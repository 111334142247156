import React from "react";

const CopyIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 512 512"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M408 112H184c-33.1 0-60 26.9-60 60v228c0 33.1 26.9 60 60 60h224c33.1 0 60-26.9 60-60V172c0-33.1-26.9-60-60-60zm20 288c0 11-9 20-20 20H184c-11 0-20-9-20-20V172c0-11 9-20 20-20h224c11 0 20 9 20 20v228z" />
    <path d="M328 32H104c-33.1 0-60 26.9-60 60v228c0 22.1 11.9 41.3 29.5 51.9 4.3 2.6 9.9 1.2 12.5-3.1 2.6-4.3 1.2-9.9-3.1-12.5C71.5 349.7 64 335.9 64 320V92c0-22.1 17.9-40 40-40h224c22.1 0 40 17.9 40 40v20c0 5.5 4.5 10 10 10s10-4.5 10-10V92c0-33.1-26.9-60-60-60z" />
  </svg>
);

export default CopyIcon;
